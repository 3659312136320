<!--This is the Inventory page, with the list of all consignment inventory. -->
<template>
    <b-container fluid class="list-container py-4">
        <b-row class="inventory-header mb-4">
            <b-col
                class="d-flex flex-column flex-grow inventory-title-wrapper"
                @click="setActiveInventoryList('spheric')"
            >
                <h1
                    class="text-primary mb-0"
                    :class="[
                        'inventory-title',
                        {active: showToric && activeInventoryType === 'spheric'},
                    ]"
                >
                    {{ t('sphericConsignmentInventory') }}
                </h1>
            </b-col>
            <div v-if="showToric" class="title-separator"></div>
            <b-col
                v-if="showToric"
                class="d-flex flex-column flex-grow inventory-title-wrapper"
                @click="setActiveInventoryList('toric')"
            >
                <h1
                    class="text-primary mb-0"
                    :class="[
                        'inventory-title',
                        {active: showToric && activeInventoryType === 'toric'},
                    ]"
                >
                    {{ t('toricConsignmentInventory') }}
                </h1>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col xl="4" lg="5" class="h-80">
                    <h4 class="heavy mb-0">{{ t('inventoryList') }}</h4>
                    <p class="gray-dark mb-0">{{ descriptionText }}</p>
                </b-col>
            </b-row>
            <InventoryTable :activeInventoryType="activeInventoryType" />
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import InventoryTable from './components/InventoryTable.vue';

export default {
    name: 'InventoryList',
    components: {InventoryTable},
    data() {
        return {
            activeInventoryType: 'spheric',
        };
    },
    computed: {
        ...mapGetters({
            inventory: 'inventory/inventoryList',
        }),
        showToric() {
            return this.inventory && this.inventory.toric;
        },
        descriptionText() {
            return this.activeInventoryType === 'spheric'
                ? this.t('inventoryListDescription')
                : this.t('inventoryListDescriptionToric');
        },
    },
    methods: {
        ...mapActions('inventory', ['clearList']),
        async refreshRecords() {
            await this.blockingRequest('inventory/fetchInventory');
        },
        setActiveInventoryList(type) {
            this.activeInventoryType = type;
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        await this.refreshRecords();
    },
};
</script>
<style scoped>
.inventory-header {
    display: flex;
    align-items: center;
    justify-content: left;
}

.inventory-title {
    margin: 0 20px;
    padding: unset;
    cursor: pointer;
    position: relative;
}

.inventory-title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    transition: background-color 0.45s, left 0.45s, width 0.45s;
}

.inventory-title.active::after {
    background-color: currentColor;
    left: 0;
    right: 0;
    width: 100%;
}

.inventory-title.active ~ .inventory-title::after {
    width: 0;
    left: 50%;
    transition: background-color 0.45s, left 0.45s, width 0.45s;
}

.title-separator {
    width: 4px;
    height: 55px;
    background-color: lightgray;
}

@media (max-width: 812px) {
    .inventory-title-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inventory-title {
        font-size: 2.5rem;
        margin: 0 10px;
    }

    .inventory-header {
        flex-direction: column;
        justify-content: center;
    }

    .title-separator {
        width: 255px;
        height: 4px;
        margin-top: 25px;
        margin-bottom: 15px;
    }
}
</style>
